// consumer_excessive_total_consumption_quarter 查询季度总体消费
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/excessive-controller/selectTotalConsumptionQuarterUsingPOST
const consumer_excessive_total_consumption_quarter = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/excessive/total/consumption/quarter',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_excessive_total_consumption_quarter;