// consumer_excessive_total_check_totalLoss 总体损失金额
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.21:8765/doc.html#/haolv-consumer/excessive-controller/checkTotalLossUsingPOST
const consumer_excessive_total_check_totalLoss = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/excessive/total/check/totalLoss',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_excessive_total_check_totalLoss;