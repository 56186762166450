import echarts from 'echarts'
import consumer_excessive_total_consumption_monthly from '@/lib/data-service/haolv-default/consumer_excessive_total_consumption_monthly'
import consumer_excessive_total_consumption_quarter from '@/lib/data-service/haolv-default/consumer_excessive_total_consumption_quarter'
import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
import consumer_excessive_total_consumption_list from '@/lib/data-service/haolv-default/consumer_excessive_total_consumption_list'
import consumer_excessive_total_consumption_departmentList from '@/lib/data-service/haolv-default/consumer_excessive_total_consumption_departmentList'
import consumer_excessive_total_check_totalSavings from '@/lib/data-service/haolv-default/consumer_excessive_total_check_totalSavings'
import consumer_excessive_total_check_totalLoss from '@/lib/data-service/haolv-default/consumer_excessive_total_check_totalLoss'
import moment from "moment"; // 总体节省
export default {
    data () {
        return {
            params: {
                deptId: '',
                endTime: '',
                startTime: '',
                deptIdArr: [''],
                dateArr: [],
                timeList: []
            },
            totalInfo: {},
            options: [],
            monthType: '月度',
            monthOption: {},
            theFirstFiveDept: [],  // 前5部门消费排名
            totalSaving: [], // 总体节省
            totalLoss: [], // 总体损失
            pickerOptions: {
                disabledDate: this.disabledDate,
                onPick: this.onPick
            },
            timeOptionRange: null,
        }
    },
    components: {},
    created () {},
    mounted () {

    },
    activated () {
        this.getDepartmentList()
        this.setTimeList()

        this.getTotalConsumptionList()
        this.getTotalConsumptionDepartmentList()
        this.getTotalSavings() // 总体节省
        this.getTotalLoss() // 总体损失
        if (this.monthType === '月度') {
            this.getTotalConsumptionMonthly()
        } else {
            this.getTotalConsumptionQuarter()
        }
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterRank(val) {
            if (val === '1.0') {
                return '酒店'
            } else if (val === '2.0') {
                return '火车票'
            } else if (val === '3.0') {
                return '国内机票'
            } else if (val === '4.0') {
                return '服务费'
            } else {
                return ''
            }
        }
    },
    methods: {
        search() {
            this.getTotalConsumptionList()
            this.getTotalConsumptionDepartmentList()
            this.getTotalSavings() // 总体节省
            this.getTotalLoss()
            if (this.monthType === '月度') {
                this.getTotalConsumptionMonthly()
            } else {
                this.getTotalConsumptionQuarter()
            }
        },
        getTotalConsumptionList() {
            consumer_excessive_total_consumption_list(this.params).then(res => {
                this.totalInfo = res
            })
        },
        setTimeList() {
            if (this.params.timeList.length === 0) {
                let now = moment()
                let year = now.year()
                let month = now.month()
                console.log(year, month)
                let timeList = []
                for (let i = 1, l = month + 2; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }
                console.log(timeList)
                let startTime = `${year}-01`
                let endTime = `${year}-${month > 8 ? (month + 1) : '0' + (month + 1)}`
                this.params.timeList = timeList
                this.params.startTime = startTime
                this.params.endTime = endTime
            }
        },
        getTotalConsumptionMonthly() {
            let monthNameArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
            let monthArr = []
            consumer_excessive_total_consumption_monthly(this.params).then(res => {
                let month = res.month
                if (month === null) {
                    return
                }
                month.forEach(value => {
                    monthArr.push(value.money)
                })
                this.monthOption = {
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 160
                    },
                    xAxis: {
                        // type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '金额',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666'
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    series: [{
                        data: monthArr,
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }]
                }
            }).catch(() => {
                this.monthOption = {
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 32
                    },
                    xAxis: {
                        // type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '金额',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666'
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    series: [{
                        data: [],
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }]
                }
            })
        },
        getTotalConsumptionQuarter() {
            let quarterNameArr = ['one', 'two', 'three', 'four']
            let quarterArr = []
            consumer_excessive_total_consumption_quarter(this.params).then(res => {
                let quarter = res.quarter
                quarterNameArr.forEach(value => {
                    quarterArr.push(quarter[value])
                })
                this.monthOption = {
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 32
                    },
                    xAxis: {
                        // type: 'category',
                        data: ['第一季度', '第二季度', '第三季度', '第四季度'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '金额',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666'
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    series: [{
                        data: quarterArr,
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }]
                }
            }).catch(() => {
                this.monthOption = {
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 32
                    },
                    xAxis: {
                        // type: 'category',
                        data: ['第一季度', '第二季度', '第三季度', '第四季度'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '金额',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666'
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    series: [{
                        data: [],
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }]
                }
            })
        },
        changeMonthType(val) {
            if (val === '月度') {
                this.getTotalConsumptionMonthly()
            } else {
                this.getTotalConsumptionQuarter()
            }
        },
        // 获取部门列表
        getDepartmentList() {
            consumer_department_getDepartmentList().then(res => {
                let departmentList = res.datas.departmentListResults
                departmentList.unshift({deptName: '全部', deptId: ''})
                this.options = departmentList
            })
        },
        changeDeptId(val) {
            if (val.length === 0) {
                this.params.deptId = ''
            } else {
                this.params.deptId = val[0]
            }
        },
        changeOrderDate(val) {
            console.log(val)
            if (val === '' || val === null) {
                this.params.startTime = ''
                this.params.endTime = ''
                this.params.timeList = []
                this.setTimeList()
            } else {
                this.params.startTime = val[0]
                this.params.endTime = val[1]
                let year = this.params.startTime.split('-')[0]
                let startMonth = parseInt(this.params.startTime.split('-')[1])
                let endMonth = parseInt(this.params.endTime.split('-')[1])
                let timeList = []
                for (let i = startMonth, l = endMonth + 1; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }

                this.params.timeList = timeList
            }
        },
        disabledDate(time) {
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange
            if (timeOptionRange) {
                // 如果有获取时间
                let year = moment(timeOptionRange).year()
                let timeYear = moment(time).year()
                return year !== timeYear
            } else {
                // 没有获取过时间，则全部都过
                return false
            }
        },
        onPick({maxDate, minDate}) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        getTotalConsumptionDepartmentList() {
            consumer_excessive_total_consumption_departmentList(this.params).then(res => {
                this.theFirstFiveDept = res.theFrstFiveVO
            })
        },
        // 总体节省
        getTotalSavings() {
            consumer_excessive_total_check_totalSavings(this.params).then(res => {
                this.totalSaving = res.results
            })
        },
        // 总体损失
        getTotalLoss() {
            consumer_excessive_total_check_totalLoss(this.params).then(res => {
                this.totalLoss = res.results
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
