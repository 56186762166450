// consumer_excessive_total_check_totalSavings 总体节省
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.21:8765/doc.html#/haolv-consumer/excessive-controller/checkTotalSavingsUsingPOST
const consumer_excessive_total_check_totalSavings = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/excessive/total/check/totalSavings',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_excessive_total_check_totalSavings;